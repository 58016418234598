@import "node_modules/bootstrap/less/bootstrap";

// @import "./spark/spark";
@import "./../../../vendor/laravel/spark/resources/assets/less/spark";

@import "./style.less";
@import "./eliteadmin.less";
@import "./material.less";
@import "./pages.less";
@import "./responsive.less";
@import "./sidebar-nav.less";
@import "./spinners.css";
@import "./variables.less";
@import "./widgets.less";

//colors

@import "./colors/gray.less";

//icons
