@btn-box-shadow:0 3px 4px 1px rgba(0,0,0,.1);
@btn-box-shadow-hover:0 1px 4px 0 rgba(0,0,0,.2);
@box-shadow:0 1px 4px 0 rgba(0,0,0,.1);
@text-shadow:rgba(0,0,0,.15) 0 0 1px;


body, h1, h2, h3, h4, h5, h6 {
  text-shadow:@text-shadow;
}
.scale-up{ 
    border:1px solid @border; 
    border-radius:@radius; 
    box-shadow:0 3px 12px rgba(0,0,0,.05)!important; 
    padding-bottom:8px; 
    margin-top:0px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    transform: scale(0);
    display:inline-block;
    transform-origin: right 0px;
}
.open>.scale-up{
  transform: scale(1);
  transform-origin: right 0px;
}


/*panels*/
.white-box, .panel{
   box-shadow:@box-shadow; 
   border-radius:@radius;  
}

/*Buttons*/
.btn{
  box-shadow:@btn-box-shadow-hover;

  &:hover{
     box-shadow:@btn-box-shadow; 
  }
}
.well, pre{
  box-shadow:@box-shadow;
}
