// --Sidebar

.sidebar {
  
  overflow-y:auto;
    .sidebar-nav.navbar-collapse {
        padding-left: 0;
        padding-right: 0;
       
    }
   .fa-fw{
   		width:20px;
   		text-align:left!important;
   		display:inline-block;
      font-size:16px;
      vertical-align:middle;
   } 
   .label{
   	 font-size:10px;
   	 border-radius: 60px;
     padding: 6px 8px;
     min-width: 30px; 
     height: 20px;
   }

}
.sidebar #side-menu .user-pro{
    
     background:url(../../plugins/images/profile-menu.png) no-repeat center center / cover;
     
     a{
        padding-left:20px;
        color:@white;
     }
     .nav-second-level a:hover{
          
          background:rgba(0, 0, 0, 0.4);
        }
      .arrow{
          top:23px;
          right:20px;
      }
      > a{
        padding:17px 7px 16px 15px!important;
      }
      .img-circle{
        width:30px;
        margin-right:10px;
      }
      .nav-second-level{
          
        li i{
            margin-right:5px;
        }  
      } 
   }
.sidebar .sidebar-search {
    padding: 15px;
}
.content-wrapper .sidebar #side-menu .user-pro{
    background-image:none;
    a{
      background:rgba(0, 0, 0, 0.0);
      color:@sidebar-text;
    }
    .nav-second-level a:hover{
          
          background:rgba(0, 0, 0, 0.0);
        }
}
#side-menu li.active > a{
	background:rgba(0, 0, 0, 0.02);
}
#side-menu li a{
	color:@sidebar-text;
  border-left:3px solid transparent;
}

#side-menu > li > a {
	padding:15px 30px 15px 15px;
	
	&:hover, &:focus{
		background:rgba(0, 0, 0, 0.1);
	}
   &.active {
            border-left:3px solid @themecolor;
            color:@white;
            background:rgba(0, 0, 0, 0);
        }
}
#side-menu ul > li > a {
	&:hover{
		color:@themecolor;
		background:none;
	}
	&.active{
	 color:@themecolor;
	}
}

.sidebar .arrow {
    position:absolute;
    right:15px;
    top:18px;
}
.sidebar .nav-second-level .arrow {
    
    top:12px;
}
.sidebar .fa.arrow:before {
    content: "\f105";
}

.sidebar .active > a > span > .fa.arrow:before {
    content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
    border-bottom: none !important;
}

.sidebar .nav-second-level li a {
    padding-left: 43px;
}

.sidebar .nav-third-level li a {
    padding-left: 52px;
}
.content-wrapper .nicescroll-rails { display:none!important;}

.fix-sidebar .top-left-part{
  background:@themecolor;
}
